article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

audio, video, canvas {
  display: inline-block;
}

html, body, h1, h2, h3, h4, h5, h6, hr, p, pre, blockquote, figure, figcaption, table, ul, ol, li, dl, dt, dd {
  margin: 0;
  padding: 0;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
}

blockquote, q {
  quotes: "" "";
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

iframe, a img {
  border: 0;
}

img, video, object, embed {
  -ms-interpolation-mode: bicubic;
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}
/*# sourceMappingURL=alxgbsn.co.uk.63e326d4.css.map */
